import React, { useState, useEffect } from "react";
import { auth, db } from "./firebase";

const Profile = () => {
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState({});

  useEffect(() => {
    // Define an asynchronous function
    const fetchUserData = async (uid) => {
      try {
        const userDoc = await db.collection("user").doc(uid).get();
        if (userDoc.exists) {
          setUserData(userDoc.data());
          console.log(userDoc);
        }
      } catch (error) {
        console.error("Failed to fetch user data:", error);
      }
    };

    // Setup the auth state change listener
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        console.log(authUser.uid);
        setUser(authUser);
        fetchUserData(authUser.uid); // Invoke the async function here
      } else {
        setUser(null);
        setUserData({});
      }
    });

    // Return the cleanup function
    return () => {
      unsubscribe(); // This will un-register the onAuthStateChanged listener
    };
  }, [db]); // Including db in dependency array in case it changes, though it likely won't

  return (
    <div className="container mx-auto p-4 lg:p-6 flex flex-col lg:flex-row lg:space-x-4 lg:pt-28">
      {/* Sidebar */}
      <aside className="flex flex-col w-full lg:w-1/4 p-4 mb-4 lg:mb-0 bg-white rounded-lg shadow-md items-center">
        {user && (
          <>
            <img
              src={user.photoURL || "default-avatar-url"}
              alt="User"
              className="w-32 h-32 rounded-full"
            />
            <h2 className="text-2xl font-semibold mt-2">
              {userData.name || user.name || "User"}
            </h2>
            <button className="bg-blue-500 text-white w-full py-2 rounded-lg mt-4">
              Change Password
            </button>
            <button
              onClick={() => auth.signOut()}
              className="bg-red-500 text-white w-full py-2 rounded-lg mt-4"
            >
              Logout
            </button>
          </>
        )}
      </aside>

      {/* Main Content */}
      <div className="w-full lg:w-3/4 p-4 bg-white rounded-lg shadow-md">
        {user ? (
          <div className="space-y-4">
            <div className="bg-gray-200 rounded-md p-4">
              <h2 className="text-2xl font-semibold text-center">
                Personal Information
              </h2>
            </div>
            <div className="p-6 space-y-4">
              <div>
                <label className="block text-gray-700 mb-2">Name:</label>
                <input
                  type="text"
                  value={userData.name || ""}
                  readOnly
                  className="w-full p-2 border rounded-lg"
                />
              </div>
              <div>
                <label className="block text-gray-700 mb-2">
                  Email Address:
                </label>
                <input
                  type="text"
                  value={user.email}
                  readOnly
                  className="w-full p-2 border rounded-lg"
                />
              </div>
              <div>
                <label className="block text-gray-700 mb-2">Courses:</label>
                <ul className="list-disc pl-5">
                  {userData.courses &&
                    userData.courses.map((course) => (
                      <li key={course}>{course}</li>
                    ))}
                </ul>
              </div>
              {/* Add more fields as per the design */}
            </div>
          </div>
        ) : (
          <div className="text-center py-6">
            <p className="text-xl font-semibold">
              Please sign in to view your profile.
            </p>
            <button
              onClick={() =>
                auth.signInWithPopup(/* Add your preferred authentication provider here */)
              }
              className="mt-4 bg-blue-500 text-white rounded-full py-2 px-4 hover:bg-blue-600 transition duration-300 ease-in-out"
            >
              Sign In
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Profile;
