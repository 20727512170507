import { Link } from "react-router-dom";

export default function Footer() {
  return <Foot />;
}
export const Foot = () => {
  return (
    <div className="bg-gray-900">
      <div className="px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
        <div className="grid row-gap-10 mb-8 lg:grid-cols-6">
          <div className="grid grid-cols-2 gap-5 row-gap-8 lg:col-span-4 md:grid-cols-4">
            <div>
              <p className="font-medium tracking-wide text-gray-300">Pages</p>
              <ul className="mt-2 space-y-2">
                <li>
                  <a
                    href="https://www.avaintern.com/"
                    className="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-200"
                  >
                    Home
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.avaintern.com/about"
                    className="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-200"
                  >
                    About Us
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.avaintern.com/career"
                    className="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-200"
                  >
                    career
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.avaintern.com/contact"
                    className="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-200"
                  >
                    contact
                  </a>
                </li>
              </ul>
            </div>

            <div>
              <p className="font-medium tracking-wide text-gray-300">
                Top Courses
              </p>
              <ul className="mt-2 space-y-2">
                <li>
                  <a
                    href="/"
                    className="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-200"
                  >
                    Web Development
                  </a>
                </li>
                <li>
                  <a
                    href="/"
                    className="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-200"
                  >
                    DSA
                  </a>
                </li>
                <li>
                  <a
                    href="/"
                    className="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-200"
                  >
                    Data Science
                  </a>
                </li>
                <li>
                  <a
                    href="/"
                    className="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-200"
                  >
                    Beginner To Advanced : java
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="md:max-w-md lg:col-span-2">
            <span className="text-base font-medium tracking-wide text-gray-300">
              Subscribe for updates
            </span>
            <form className="flex flex-col mt-4 md:flex-row">
              <input
                placeholder="Email"
                required
                type="text"
                className="flex-grow w-full h-12 px-4 mb-3 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none md:mr-2 md:mb-0 focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
              />
              <button
                type="submit"
                className="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-widetransition duration-200 rounded shadow-md md:w-auto text-black  bg-yellow-400  hover:bg-white hover:text-yellow-400 focus:shadow-outline focus:outline-none"
              >
                Subscribe
              </button>
            </form>
            <p className="mt-4 text-sm text-gray-500">
              Banglore,Neraluru SMS complex 1st floor Karnataka,562107
            </p>
          </div>
        </div>
        <div className="flex flex-col justify-between pt-5 pb-10 border-t border-gray-800 sm:flex-row">
          <p className="text-sm text-gray-500">© Copyright 2023 AVA INTERN</p>
          <div className="flex items-center mt-4 space-x-4 sm:mt-0">
            <a
              href="https://www.instagram.com/ava_intern?igshid=YmMyMTA2M2Y%3D"
              className="text-gray-500 transition-colors duration-300 hover:text-teal-accent-400"
            >
              <svg viewBox="0 0 30 30" fill="currentColor" className="h-6">
                <circle cx="15" cy="15" r="4" />
                <path d="M19.999,3h-10C6.14,3,3,6.141,3,10.001v10C3,23.86,6.141,27,10.001,27h10C23.86,27,27,23.859,27,19.999v-10   C27,6.14,23.859,3,19.999,3z M15,21c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S18.309,21,15,21z M22,9c-0.552,0-1-0.448-1-1   c0-0.552,0.448-1,1-1s1,0.448,1,1C23,8.552,22.552,9,22,9z" />
              </svg>
            </a>
            <a
              href="https://www.linkedin.com/company/ava-intern/"
              className="text-gray-500 transition-colors duration-300 hover:text-teal-accent-400"
            >
              <svg viewBox="0 0 24 24" fill="currentColor" className="h-5">
                <path d="M20.447,20.452h-3.554v-5.569c0-1.328-0.025-3.037-1.852-3.037c-1.853,0-2.136,1.445-2.136,2.939v5.667h-3.553V9h3.414v1.561h0.049c0.477-0.9,1.637-1.85,3.37-1.85c3.601,0,4.261,2.369,4.261,5.455V20.452z M5.337,7.433c-1.144,0-2.063-0.926-2.063-2.065c0-1.138,0.919-2.063,2.063-2.063c1.14,0,2.064,0.925,2.064,2.063C7.401,6.507,6.478,7.433,5.337,7.433z M7.119,20.452H3.565V9h3.554V20.452z M22.225,0H1.771C0.792,0,0,0.792,0,1.771v20.458C0,23.208,0.792,24,1.771,24h20.451C23.206,24,24,23.208,24,22.229V1.771C24,0.792,23.206,0,22.225,0z" />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
