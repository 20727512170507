import React, { useState, useEffect } from "react";
import CourseCard from "./CourseCard";
import { auth, db } from "./firebase";

export default function Course() {
  const [courses, setCourses] = useState([]);
  const [userUID, setUserUID] = useState(null);

  useEffect(() => {
    // Listen for changes in the authentication state
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUserUID(user.email);
      }
    });

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchUserCourses = async () => {
      if (userUID) {
        const userDoc = await db.collection("user").doc(userUID).get();

        if (!userDoc.exists || !userDoc.data()) {
          console.warn("User document not found or has no data.");
          return;
        }

        const userCourses = userDoc.data().courses || [];

        // Then, fetch the detailed information for each course
        const courseDocs = await Promise.all(
          userCourses.map((courseId) =>
            db.collection("courses").doc(courseId).get()
          )
        );

        // Transform the course documents to their data
        const fetchedCourses = courseDocs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        setCourses(fetchedCourses);
      }
    };

    fetchUserCourses();
  }, [userUID]); // Re-run this effect when the userUID changes
  return (
    <section className="bg-gray-100 text-gray-100 py-12">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-semibold text-black text-center lg:mt-16 mt-8">
          My Courses
        </h2>
        {userUID ? (
          courses.length === 0 ? (
            <p className="text-center mt-4 text-4xl p-12 text-black">
              No courses enrolled
            </p>
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12">
              {courses.map((course, index) => (
                <CourseCard key={course.id} {...course} />
              ))}
            </div>
          )
        ) : (
          <p className="text-center mt-4">No courses enrolled</p>
        )}
      </div>
    </section>
  );
}
