import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { db, auth } from "./firebase";

const MainSection = () => {
  const { courseName } = useParams();
  const navigate = useNavigate();
  const [course, setCourse] = useState(null);
  const [currentVideo, setCurrentVideo] = useState(null);

  useEffect(() => {
    const fetchUserAndCourseData = async () => {
      try {
        const user = auth.currentUser;

        if (!user) {
          navigate("/");
          return;
        }

        const userDoc = await db.collection("user").doc(user.email).get();
        console.log(user);
        const userCourses = userDoc.data().courses;

        if (!userCourses.includes(courseName)) {
          navigate("/d");
          return;
        }

        const courseDoc = await db.collection("courses").doc(courseName).get();

        if (!courseDoc.exists) {
          navigate("/");
          return;
        }

        setCourse(courseDoc.data());

        const courseData = courseDoc.data();
        if (courseData.videos && courseData.videos.length) {
          setCurrentVideo(courseData.videos[0]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        navigate("/");
      }
    };

    fetchUserAndCourseData();
  }, [courseName, navigate]);

  return (
    <main className="h-full flex flex-col md:flex-row p-6 pt-32 bg-gray-100">
      <section className="w-full md:w-3/4 m-2 p-4 mb-4 md:mb-0 md:ml-4 bg-white rounded border shadow-md">
        <div
          className="bg-black w-full  mb-4 rounded relative"
          style={{ height: "30rem" }}
        >
          {currentVideo && (
            <video
  className="absolute inset-0 w-full h-full rounded"
  controls
  controlsList="nodownload" // Added controlsList attribute
  key={currentVideo.url}
>
  <source src={currentVideo.url} type="video/mp4" />
  Your browser does not support the video tag.
</video>

          )}
        </div>
        <h1 className="text-xl font-bold mb-4 border-b-2 pb-2">
          {course && course.title}
        </h1>

        <p>{course && course.description}</p>
      </section>

      <aside className="w-full md:w-1/4 bg-white p-4 rounded-lg border m-2 shadow-md">
        <h2 className="font-bold text-xl mb-6 border-b-2 pb-2 ">
          Course Content
        </h2>
        <div className="overflow-y-auto " style={{ height: "30rem" }}>
          {" "}
          <ul className="space-y-2">
            {course &&
              course.videos.map((video, index) => (
                <li
                  key={index}
                  className={`mb-1 p-2 border-b-2 rounded-md cursor-pointer hover:bg-gray-200 hover:text-black transition
          ${
            currentVideo.title === video.title ? "bg-yellow-400 text-black" : ""
          }`}
                  onClick={() => setCurrentVideo(video)}
                >
                  <span className="font-semibold">
                    {index + 1}. {video.title}
                  </span>
                </li>
              ))}
          </ul>
        </div>
      </aside>
    </main>
  );
};

export default MainSection;
