import React from "react";
import Card from "react-bootstrap/Card";
import { useNavigate } from "react-router-dom";

export default function CourseCard({ title, id, img }) {
  const navigate = useNavigate();

  return (
    <Card className="m-10 p-4 shadow-lg rounded-xl  bg-white ">
      <Card.Img
        variant="top"
        src={img} // Placeholder image
        className="rounded-t-xl h-48 object-cover"
      />
      <Card.Body>
        <Card.Title className="my-2 text-xl font-semibold text-black">
          {title}
        </Card.Title>

        <button
          className="my-4 bg-yellow-400   hover:bg-yellow-500 text-black font-bold py-2 px-4  border-blue-600 rounded transition duration-200 ease-in-out"
          onClick={() => navigate(`courses/${id}`)}
        >
          Start Learning
        </button>
      </Card.Body>
    </Card>
  );
}
